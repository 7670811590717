import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";
import { Socials } from "./Socials";
import { usePageContext } from "../Context/pageContext";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Social } from "../Home/social";

const Footer = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { lang } = usePageContext();
  const { footer, terms } = useStaticQuery(query);
  const [email, setEmail] = useState<string>("");
  const [result, setResult] = useState<any>(null);

  return (
    <div>
      <div
        style={{
          position: "absolute",
          backgroundColor: "#eedd24",
          width: "100%",
          bottom: "0",
          left: "0px",
          zIndex: 1,
          padding: "0 8%",
        }}
      >
        {!open ? (
          <div
            style={{
              display: "flex",
              height: "120px",
              alignItems: "center",
            }}
          >
            <SocialWrapper>
              <Socials theme="dark" />
            </SocialWrapper>
            <div style={{ margin: "0 auto" }}>
              <button
                onClick={() => setOpen(true)}
                style={{
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                  backgroundColor: "transparent",
                }}
              >
                <Text>
                  {footer?.newsletter?.callToAction &&
                    footer.newsletter.callToAction[lang]}
                </Text>
              </button>
            </div>
            <div className="flexColumn">
              <Link to="/terms">
                <Text>{terms.title[lang]}</Text>
              </Link>
              <Text>
                ©{footer?.copyright} {new Date().getFullYear()}
              </Text>
            </div>
          </div>
        ) : (
          <div
            style={{
              height: "290px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "170px",
                width: "560px",
                margin: "0 auto",
                paddingTop: "59px",
              }}
            >
              <SuscribeText>
                {footer?.newsletter?.text && footer.newsletter.text[lang]}
              </SuscribeText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "120px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SocialWrapper>
                <Socials theme="dark" />
              </SocialWrapper>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  flexDirection: "column",
                  width: "400px",
                  height: "137px",
                  margin: "0 auto",
                }}
              >
                {!result ? (
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      setResult(await addToMailchimp(email));
                    }}
                  >
                    <EmailInput
                      type="email"
                      name="email"
                      autoComplete="email"
                      required={true}
                      aria-label="email"
                      placeholder={
                        footer?.newsletter?.emailFormPlaceholder &&
                        footer.newsletter.emailFormPlaceholder[lang]
                      }
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button onClick={() => setOpen(false)}>
                        <Text>{footer?.newsletter?.closeButton[lang]}</Text>
                      </Button>
                      <Button type="submit">
                        <Text style={{ textIndent: "-7px", direction: "rtl" }}>
                          {footer?.newsletter?.subscribeButton &&
                            footer.newsletter.subscribeButton[lang]}
                        </Text>
                      </Button>
                    </div>
                  </form>
                ) : (
                  <>
                    <Thankyou>{footer.newsletter.thankMessage[lang]}</Thankyou>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button onClick={() => setOpen(false)}>
                        <Text>{footer?.newsletter?.closeButton[lang]}</Text>
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div className="flexColumn">
                <Link to="/terms">
                  <Text>{terms.title[lang]}</Text>
                </Link>
                <Text>
                  ©{footer?.copyright} {new Date().getFullYear()}
                </Text>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const query = graphql`
  query {
    footer: sanityFooter {
      newsletter {
        callToAction {
          en
          es
        }
        closeButton {
          en
          es
        }
        emailFormPlaceholder {
          en
          es
        }
        subscribeButton {
          en
          es
        }
        text {
          en
          es
        }
        thankMessage {
          en
          es
        }
      }
      copyright
    }
    terms: sanityTerm {
      title {
        en
        es
      }
    }
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  width: 150px;
  height: 22px;
  /* justify-content: space-between; */
  flex-direction: row;
  transform: scale(1);
  transition: transform 0.6s ease;
  & > a:hover {
    transform: scale(1.1);
  }
`;

const Text = styled.text`
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  line-height: 250%;
  text-decoration: none;
  color: #000;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  /* margin: 0 auto; */
`;

const EmailInput = styled.input`
  width: 400px;
  height: 38px;
  /* background-color: #494623; */
  background-color: #423c01;
  color: #eedd24;
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 200%;
  /* or 32px */
  text-align: center;
  margin-bottom: 14px;
  letter-spacing: 0.05em;
  border: none;
  color: #eedd24;
`;

const SuscribeText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 200%;
  /* or 32px */

  text-align: center;
  letter-spacing: 0.05em;
  padding-bottom: 33px;

  color: #000000;
`;
const Thankyou = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  /* or 32px */

  text-align: center;
  letter-spacing: 0.05em;
  padding-bottom: 19px;

  color: #000000;
`;
export default Footer;
