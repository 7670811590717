import React, { useState } from "react";
import { NavBarLink } from "./navBarLink";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { usePageContext } from "../Context/pageContext";

import * as styles from "../../styles/navBarMobile.module.scss";

interface INavBarSubMenu {
  theme: string;
  label: string;
  subMenus: any;
}

export const NavBarSubMenu: React.FC<INavBarSubMenu> = ({
  theme,
  label,
  subMenus,
}) => {
  const [open, setOpen] = useState(false);
  const { lang } = usePageContext();
  return (
    <div
      // onClick={() => setOpen(!open)}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      style={{
        position: "relative",
        cursor: "pointer",
        textAlign: "left",
        height: (subMenus.length + 1) * 35 + "px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {label}
        {open ? (
          <MdKeyboardArrowUp className={styles.icon} />
        ) : (
          <MdKeyboardArrowDown className={styles.icon} />
        )}
      </div>
      {open &&
        subMenus.map((subMenu: any, index: number) => {
          const top = (index + 1) * 33 + "px";
          return (
            <React.Fragment key={label + "-submenu-item-" + index}>
              <div style={{ position: "absolute", top }}>
                <NavBarLink
                  theme={theme}
                  label={subMenu.label[lang]}
                  dest={subMenu.destinationPath[lang]}
                />
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};
