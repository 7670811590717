import React, { ReactNode } from "react";
import { Header } from "./header";
import "../styles/layout.scss";
import Footer from "./Footer/footer";
import { Contact } from "./contact";
import { FooterMobile } from "./Footer/footerMobile";
import { useWindowSize } from "./Utils/useWindowSize";

interface Props {
  children: ReactNode;
  theme: string;
  withoutLogo?: boolean;
}

const Layout: React.FC<Props> = ({ children, theme, withoutLogo }) => {
  const dimensions = useWindowSize();

  return (
    <>
      <Header theme={theme} withoutLogo={withoutLogo} />
      <div>
        <main>{children}</main>
        <button id="scroll-btn" className="sidebar-btns" title="Scroll to top">
          Up
        </button>
      </div>
      <Contact />

      {dimensions.width > 1023 ? <Footer /> : <FooterMobile />}
    </>
  );
};

export default Layout;
