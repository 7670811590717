import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { Socials } from "./Socials";
import { usePageContext } from "../Context/pageContext";
import addToMailchimp from "gatsby-plugin-mailchimp";
import * as styles from "../../styles/footer.module.scss";

export const FooterMobile = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { lang } = usePageContext();
  const { footer, terms } = useStaticQuery(newsletterQuery);
  const [email, setEmail] = useState<string>("");
  const [result, setResult] = useState<any>(null);

  return (
    <div className={styles.wrapper}>
      <div className={styles.footerMargin}>
        {!open ? (
          <div className={styles.ctaWrapper}>
            {!result ? (
              <button
                onClick={() => setOpen(true)}
                className={styles.ctaButton}
              >
                {footer?.newsletter?.callToAction &&
                  footer.newsletter.callToAction[lang]}
              </button>
            ) : (
              <div style={{ marginBottom: "20px" }}>
                {footer?.newsletter?.thankMessage[lang]}
              </div>
            )}
          </div>
        ) : (
          <>
            <div className={styles.text}>
              {footer?.newsletter?.text && footer.newsletter.text[lang]}
            </div>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await setResult(await addToMailchimp(email));
                setOpen(false);
              }}
              style={{ marginBottom: 0, width: "100%" }}
            >
              <input
                type="email"
                name="email"
                aria-label="email"
                autoComplete="email"
                placeholder={
                  footer?.newsletter?.emailFormPlaceholder &&
                  footer.newsletter.emailFormPlaceholder[lang]
                }
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                className={styles.emailInput}
              />
              <div className={styles.buttonsWrapper}>
                <button
                  type="submit"
                  className={styles.ctaButton}
                  //   style={{ textIndent: "-7px", direction: "rtl" }}
                  style={{ marginBottom: "20px" }}
                >
                  {footer?.newsletter?.subscribeButton &&
                    footer.newsletter.subscribeButton[lang]}
                </button>
                <button
                  onClick={() => setOpen(false)}
                  className={styles.ctaButton}
                  style={{ marginBottom: "20px" }}
                >
                  {footer?.newsletter?.closeButton[lang]}
                </button>
              </div>
            </form>
          </>
        )}

        <div className={styles.socialWrapper}>
          <Socials theme="dark" />
        </div>
        <div className={styles.cpWrapper}>
          <Link
            to="/terms"
            style={{ textDecoration: "none", color: "#202023" }}
          >
            {terms.title[lang]}
          </Link>
        </div>
        <div className={styles.cpWrapper}>
          ©{footer?.copyright} {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
};

const newsletterQuery = graphql`
  query {
    footer: sanityFooter {
      newsletter {
        callToAction {
          en
          es
        }
        closeButton {
          en
          es
        }
        emailFormPlaceholder {
          en
          es
        }
        subscribeButton {
          en
          es
        }
        text {
          en
          es
        }
        thankMessage {
          en
          es
        }
      }
      copyright
    }
    terms: sanityTerm {
      title {
        en
        es
      }
    }
  }
`;
