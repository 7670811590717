import React from "react";
import * as styles from "../../styles/navBarMobile.module.scss";

interface Props {
  onClick: any;
  theme: string;
}

export const BurgerMenu: React.FC<Props> = ({ theme, onClick }) => {
  const handleClick = () => {
    const element = document.getElementById("burgerMenu");
    if (element) element.classList.toggle(styles.active);
    onClick();
  };
  return (
      <div className={styles.burgerMenuWrapper} onClick={handleClick}>
    <div className={`${styles.burgerMenu}`} >
      <span className={theme === "light" ? styles.whiteB : styles.black}></span>
      <span className={theme === "light" ? styles.whiteB : styles.black}></span>
      <span className={theme === "light" ? styles.whiteB : styles.black}></span>
</div>
    </div>
  );
};
