import { Link } from "gatsby";
import React, { useState } from "react";

import * as styles from "../../styles/navBarMobile.module.scss";
import { usePageContext } from "../Context/pageContext";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

interface Props {
  menuItem: any;
}

export const NavBarMobileMenuItem: React.FC<Props> = ({ menuItem }) => {
  const { lang } = usePageContext();
  const [open, setOpen] = useState(false);
  if (menuItem.subMenus.length === 0) {
    return (
      <Link to={menuItem.destinationPath[lang]} className={styles.menuItem}>
        {menuItem.label[lang]}
      </Link>
    );
  }

  return (
    <>
      <div className={styles.row}>
        <button className={styles.menuItem} onClick={() => setOpen(!open)}>
          {menuItem.label[lang]}
        </button>
        {!open ? (
          <MdKeyboardArrowDown className={styles.icon} />
        ) : (
          <MdKeyboardArrowUp className={styles.icon} />
        )}
      </div>
      <div className={styles.subItemWrapper}>
        {open &&
          menuItem.subMenus.map((item: any, index: number) => (
            <React.Fragment key={`subitem-${index}`}>
              <Link to={item.destinationPath[lang]} className={styles.subItem}>
                {item.label[lang]}
              </Link>
            </React.Fragment>
          ))}
      </div>
    </>
  );
};
