import { Link, useStaticQuery, graphql } from "gatsby";
import React, { ReactNode } from "react";
// import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { usePageContext } from "../Context/pageContext";
import Img from "gatsby-image";

interface ILogo {
  theme: string;
}
export const LogoMobile: React.FC<ILogo> = ({ theme }) => {
  const { lang } = usePageContext();
  const { logo } = useStaticQuery(LogoQuery);
  // const image = logo[theme][0].image?.asset?.fixed;
  const url = logo[theme].image?.asset?.url;
  const alt = logo[theme][lang].alt;

  return (
    <Link to="/">
      <img src={url} alt={alt} style={{ width: "100%", height: "100%" }} />
    </Link>
  );
};

const LogoQuery = graphql`
  query {
    logo: sanitySiteSettings {
      dark: logoDark {
        en {
          alt
        }
        es {
          alt
        }
        image {
          asset {
            url
            # fixed(width: 120, height: 39) {
            #   ...GatsbySanityImageFixed
            # }
          }
        }
      }
      light: logoLight {
        en {
          alt
        }
        es {
          alt
        }
        image {
          asset {
            url
            # fixed(width: 120, height: 39) {
            #   ...GatsbySanityImageFixed
            # }
          }
        }
      }
    }
  }
`;
