import { graphql, useStaticQuery } from "gatsby";
import React, { useRef, useState } from "react";
import * as styles from "../../styles/navBarMobile.module.scss";
import { usePageContext } from "../Context/pageContext";
import { LogoMobile } from "./logoMobile";
import { NavBarMobileMenuItem } from "./navBarMobileMenuItem";
import { BurgerMenu } from "./burgerMenu";
import { Socials } from "../Footer/Socials";

interface Props {
  theme: string;
  withoutLogo?: boolean;
}
export const NavBarMobile: React.FC<Props> = ({ theme, withoutLogo }) => {
  const { menu } = useStaticQuery(query);
  const { lang } = usePageContext();
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClick = () => {
    const element = document.getElementById("navvMenu");
    if (element) {
      element.classList.toggle("inactive")
      const menu = document.getElementById("mobile-menu");
      if (menu) menu.style.top = element.offsetHeight + 'px'
  };
    console.log(`element`, element)
  };

  return (
    <div
      id="navMenu"
      className={`${styles.wrapper} ${theme === "dark" && "trameLight"}`}
    >
      <div id="navvMenu" className={`${styles.background} inactive`}>
        <div className={`${styles.menu} margin`}>
          {menu?.menuItems?.map((menuItem: any, index: number) => {
            return (
              <React.Fragment key={"navbar-item-" + index}>
                <NavBarMobileMenuItem menuItem={menuItem} />
              </React.Fragment>
            );
          })}
        </div>
        <div className="margin">
          <div className={styles.socials}>
            <Socials theme="dark" />
          </div>
        </div>
      </div>
      {/* <div style={{ background: theme === "light" ? "#202023" : undefined }}> */}
      <div >
        {!withoutLogo && <div
          id="mobile-menu"
          className={`${styles.navBarWrapper} ${
            withoutLogo && styles.withoutLogo
          }`}
        >
          {withoutLogo === undefined && (
            <>
              <div className={styles.mobileLogo}>
                <LogoMobile theme={theme} />
              </div>

              <div
                id="burgerMenu"
                style={{
                  transition: "transform 0.5s",
                }}
              >
                <BurgerMenu onClick={handleClick} theme={theme} />
              </div>
            </>
          )}
        </div>}
      </div>
    </div>
  );
};

const query = graphql`
  query {
    menu: sanityMenuLayout {
      menuItems {
        destinationPath {
          en
          es
        }
        label {
          en
          es
        }
        subMenus {
          destinationPath {
            en
            es
          }
          label {
            en
            es
          }
        }
      }
    }
  }
`;
