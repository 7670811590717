import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { usePageContext } from "./Context/pageContext";
import SEO from "./seo";
import addToMailchimp from "gatsby-plugin-mailchimp";
import axios from "axios";
import BlockContent from "@sanity/block-content-to-react";
// import * as styles from "../styles/contact.module.scss";
const styles = require("../styles/contact.module.scss");

const FORM =
  "https://us1.list-manage.com/contact-form/post?u=d6533712132b622b422cb9e88&form_id=354735996b3ed158f6da8e3c870232ab";
export const Contact: FunctionComponent = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const { form } = useStaticQuery(query);
  const { lang } = usePageContext();
  const raw = "_raw" + lang.charAt(0).toUpperCase() + lang.slice(1);
  const [result, setResult] = useState<boolean>(false);
  const [serverState, setServerState] = useState({
    submitting: false,
    status: {},
  });

  const handleServerResponse = (ok: any, msg: any, form: any) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
      setResult(true);
    }
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    if (data.get("newsletter")) {
      const email = data.get("email");
      addToMailchimp(email);
    }
    axios({
      method: "post",
      url: "https://getform.io/f/372049b5-bcae-452f-b266-45dd8957a1be",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <ContactBackground id="contact">
      <ContactWraper>
        <p className={styles.title}>{form.title[lang]}</p>
        <div className={styles.text}>
          {!result ? (
            <BlockContent blocks={form.description[raw]} />
          ) : (
            <>{form.thankMessage[lang]}</>
          )}
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            handleOnSubmit(e);
          }}
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            paddingTop: "5%",
          }}
        >
          <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              color: "white",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "200%",
              // lineHeight: "250%",
              // lineHeight="40px"
              letterSpacing: 0,
              textAlign: "left",
              width: "100%",
            }}
          >
            <InputLabel htmlFor="text">{form.nameField[lang]}*</InputLabel>
            <input
              id="text"
              type="text"
              required={true}
              name="name"
              autoComplete="off"
              className={styles.input}
            />
            <InputLabel htmlFor="email">{form.emailField[lang]}*</InputLabel>
            <input
              id="email"
              type="email"
              required={true}
              name="email"
              className={styles.input}
            />
            <InputLabel htmlFor="subject">{form.subjectField[lang]}</InputLabel>
            <input
              id="subject"
              type="text"
              name="subject"
              autoComplete="off"
              className={styles.input}
            />
            <InputLabel htmlFor="message">
              {form.messageField[lang]}*
            </InputLabel>
            <textarea
              // type="texttarea"
              id="message"
              required={true}
              name="message"
              autoComplete="off"
              className={`${styles.input} ${styles.textarea}`}
            />
            <div className={styles.radioWrapper}>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setChecked(!checked);
                }}
              >
                <InputRadio
                  id="newsletterRadio"
                  type="radio"
                  name="newsletter"
                  checked={checked}
                  onChange={() => {}}
                />
                <RadioLabel htmlFor="newsletterRadio">
                  {form.newsletterOptinField[lang]}
                </RadioLabel>
              </div>
              <div className={styles.submitB}>
                <SubmitButton type="submit">
                  {form.submitButton[lang]}
                </SubmitButton>
              </div>
            </div>
          </div>
        </form>
      </ContactWraper>
    </ContactBackground>
  );
};

const query = graphql`
  query {
    form: sanityContact {
      description {
        _rawEn
        _rawEs
      }
      _id
      emailField {
        en
        es
      }
      messageField {
        en
        es
      }
      nameField {
        en
        es
      }
      newsletterOptinField {
        en
        es
      }
      subjectField {
        en
        es
      }
      submitButton {
        en
        es
      }
      title {
        en
        es
      }
      thankMessage {
        en
        es
      }
    }
  }
`;
const flexrow_d = styled.div`
  display: flex;
  flex-direction: row;
  h1 {
    display: none;
  }
`;

const InputLabel = styled.label`
  font-family: "Roboto";
  font-style: "normal";
  font-size: "16px";
  font-weight: 400;
  letter-spacing: 0;
  text-align: "left";
  color: white;
`;

const InputRadio = styled.input`
  opacity: 0;
  &:checked {
    + label::after {
      background: #eedd24;
    }
  }
`;

const RadioLabel = styled.label`
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-left: 18px;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 6px;
    left: -13px;
    border-radius: 50%;
    /* background-color: #494623; */
    background-color: #423c01;
    width: 18px;
    height: 18px;
  }

  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    left: -9px;
    top: 10px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
`;

const SubmitButton = styled.button`
  color: #eedd24;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 35px;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  text-align: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  text-indent: -7px;
  direction: rtl;
  transform: scale(1);
  transition: transform 0.3s ease;

  &:hover {
    /* transform: scale(1.1); */
    color: white;
  }

  @media only screen and (max-width: 1023px) {
    text-indent: 0;
    margin: 0 auto;
  }
`;

const ContactWraper = styled.div`
  /* background: #202023; */
  width: 800px;
  padding-top: 85px;
  padding-bottom: 5px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  @media only screen and (max-width: 1023px) {
    padding-top: 135px;
    width: 84vw;
    margin: auto;
  }
`;

const ContactBackground = styled.div`
  /* background: repeating-linear-gradient(
    135deg,
    black,
    black 1px,
    #202023 1px,
    #202023 6px
  ); */
  background: url("/trameDark.svg");
  background-size: 40px;
  background-color: #202023;

  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 75px;
`;
