import React from "react";
import { Logo } from "./logo";
import { graphql, useStaticQuery } from "gatsby";
import { usePageContext } from "../Context/pageContext";
import { NavBarLink } from "./navBarLink";
import { NavBarSubMenu } from "./navBarSubMenu";
import * as styles from "../../styles/navBar.module.scss";

interface INavBar {
  theme: string;
}

export const NavBar: React.FC<INavBar> = ({ theme }) => {
  const { menu } = useStaticQuery(query);
  const { lang } = usePageContext();

  return (
    <div className={(theme === "dark" && styles.trameLight) || undefined}>
      <nav
        className={`margin`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          // paddingLeft: "8%",
          // paddingRight: "8%",
          // minWidth: "84vw",
          // width: "960px",
          margin: "0 auto",
          paddingTop: "3rem",
          zIndex: 2,
        }}
      >
        <div style={{ display: "flex", width: "100%", height: "60px" }}>
          <Logo theme={theme} />
          <div
            className={styles.navBar}
            style={{
              display: "flex",
              // alignItems: "center",
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: "14px",
              textAlign: "right",
              letterSpacing: "0.5em",
              textTransform: "uppercase",
              fontStyle: "normal",
              justifyContent: "flex-end",
              width: "100%",
              flexWrap: "nowrap",
              whiteSpace: "nowrap",
              marginTop: "20px",
              color: theme === "dark" ? "#000" : "#ffffff",
            }}
          >
            {menu?.menuItems?.map((menuItem: any, index: number) => {
              return (
                <React.Fragment key={"navbar-item-" + index}>
                  {menuItem.subMenus.length ? (
                    <NavBarSubMenu
                      theme={theme}
                      label={menuItem.label?.[lang]}
                      subMenus={menuItem.subMenus}
                    />
                  ) : (
                    <NavBarLink
                      theme={theme}
                      label={menuItem.label?.[lang]}
                      dest={menuItem.destinationPath?.[lang]}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </nav>
    </div>
  );
};

const query = graphql`
  query {
    menu: sanityMenuLayout {
      menuItems {
        destinationPath {
          en
          es
        }
        label {
          en
          es
        }
        subMenus {
          destinationPath {
            en
            es
          }
          label {
            en
            es
          }
        }
      }
    }
  }
`;
