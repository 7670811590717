import { Link } from "gatsby";
import React from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import * as styles from "../../styles/navBar.module.scss";

interface INavBarLink {
  theme: string;
  label: string;
  dest: string;
}

export const NavBarLink: React.FC<INavBarLink> = ({ theme, label, dest }) => (
  <div>
    <Link to={dest} className={theme === "dark" ? styles.black : styles.white}>
      {label}
    </Link>
  </div>
);
