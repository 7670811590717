import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import * as styles from "../../styles/socials.module.scss"

interface Props {
  theme: string;
}
export const Socials: FunctionComponent<Props> = ({ theme }) => {
  const { data } = useStaticQuery(query);

  return (
    // <div
    //   style={{
    //     display: "flex",
    //     width: "150px",
    //     justifyContent: "space-between",
    //     flexDirection: "row",
    //   }}
    // >
    <>
      {data.edges.map((edge: any, index: number) => (
        <div className={styles.wrapper} key={`social-${index}`}>
        <a href={edge.node.url} target="_blank" rel="noopener">
          <SocialLogo
            src={edge.node.logos[theme].asset.url}
            alt={edge.node.title}
          />
        </a>
        </div>
      ))}
    </>
  );
};

const SocialLogo = styled.img`
  width: 24px;
  height: 24px;
  /* filter: contrast(0); */
  /* padding-left: 8px; */
  margin-bottom: 0;
`;

const query = graphql`
  query MyQuery {
    data: allSanitySocialNetwork {
      edges {
        node {
          logos {
            dark {
              asset {
                url
              }
            }
            light {
              asset {
                url
              }
            }
          }
          title
          url
        }
      }
    }
  }
`;
