import React from "react";
import { NavBar } from "./Header/navBar";
import { NavBarMobile } from "./Header/navBarMobile";
import { useWindowSize } from "./Utils/useWindowSize";

interface Props {
  theme: string;
  withoutLogo?: boolean;
}

export const Header: React.FC<Props> = ({ theme, withoutLogo }) => {
  const dimensions = useWindowSize();

  return (
    <>
      {dimensions.width > 1023 ? (
        <NavBar theme={theme} />
      ) : (
        <NavBarMobile theme={theme} withoutLogo={withoutLogo} />
      )}
    </>
  );
};
